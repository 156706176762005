import React from "react"
import { graphql } from "gatsby"

import { Activate as Page } from "../../components/Acccount/Activate/Activate"

export const query = graphql`
  query {
    page: sanityPageAccountActivate {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }): JSX.Element => <Page {...props} {...data} />
