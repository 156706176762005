import React, { useMemo } from "react"

import { useCustomerAccount } from "../../../hooks/useCustomer"

export const withActivateForm = Component =>
  React.memo(({ name = "ActivateForm", location, content }) => {
    const { activateCustomer, data, setData, loading, errors } = useCustomerAccount()

    const activateParams = location?.pathname.split("/account/activate/")[1].split("/") || []
    const customerId = (activateParams && activateParams[0]) || null
    const activationToken = (activateParams && activateParams[1]) || null

    const handleSubmit = async event => {
      event.preventDefault()
      await activateCustomer(customerId, activationToken, data?.password)
    }

    const handleChange = ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    }

    Component.displayName = name
    return useMemo(
      () => <Component content={content} data={data} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} errors={errors} />,
      [data, loading, errors]
    )
  })
